import React from 'react'
import { graphql} from 'gatsby'
import styled from "styled-components"
import { highlight, ContentContainer, dark, light } from "../components/styles"
import Img from 'gatsby-image'

type Props = {
  data: any
}

const HeaderImgContainer = styled.div`
  display: block;
  height: 300px;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  grid-column-start: 2;
  grid-row-start: 2;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
  border-radius: 20px;
`

const TitleArea = styled.div`
  display: grid;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-template-rows: auto auto;
`
const Title = styled.h1`
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: start;
  color: ${highlight};
  align-self: start;
  @media(max-width: 560px) {
    align-self: center;
  }
  font-size: 3rem;
`
const Info = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: start;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
  align-self: end;
`
const ContentArea = styled.div`
  grid-column-start: 2;
  grid-row-start: 3;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
`
const ContentText = styled.div`
  width: 100%;
`

const BlogTemplate: React.FC<Props> = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <ContentContainer>
      <TitleArea>
        <Title>{frontmatter.title}</Title>
        <Info>By: {frontmatter.author} | {frontmatter.date} | {frontmatter.topic}</Info>
      </TitleArea>
      <HeaderImgContainer>
        <Img fluid={data.img.childImageSharp.fluid} style={{ height: 300, borderRadius: 20 }}/>
      </HeaderImgContainer>
      <ContentArea>
        <ContentText dangerouslySetInnerHTML={{ __html: html }}/>
      </ContentArea>
    </ContentContainer>
  )
}
export const pageQuery = graphql`
    query($path: String!, $img: String!) {
        markdownRemark: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                summary
                author
                topic
            }
        }
        img: file(relativePath: { eq: $img }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default BlogTemplate
